import React from "react"
import { graphql } from "gatsby"
import { LandingPageQuery } from "../__generated__/LandingPageQuery"
import LandingPageContainer from "../containers/editorial/LandingPage"

import { PrismicPage, withPrismicPreview } from "@punks/contents-prismic"

interface Props {
  data: LandingPageQuery
}

const LandingPage = ({ data }: Props) => {
  return (
    <PrismicPage page={data}>
      <LandingPageContainer
        pageData={{
          title: data.prismicLandingPage?.data?.title,
        }}
      />
    </PrismicPage>
  )
}

export default withPrismicPreview(LandingPage)

export const pageQuery = graphql`
  query LandingPageQuery($id: ID!) {
    prismicLandingPage(prismicId: { eq: $id }) {
      lang
      data {
        title {
          raw
        }
      }
    }
  }
`
