import React from "react"
import DefaultLayout from "../../../layouts/DefaultLayout"
import { RichText, RichTextField } from "@punks/core"

export interface LandingPageData {
  title: RichText
}

interface Props {
  pageData: LandingPageData
}

const LandingPageContainer = ({ pageData }: Props) => {
  return (
    <DefaultLayout>
      <RichTextField value={pageData.title} />
    </DefaultLayout>
  )
}

export default LandingPageContainer
