import { makeStyles } from "@material-ui/styles"
import React from "react"
// import Footer from "./Footer"
// import Header from "./Header"

interface Props {
  children: any
}

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  header: {},
  content: {
    flex: 1,
  },
  footer: {},
}))

const DefaultLayout = ({ children }: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.header}>{/* <Header data={{}} /> */}</div>
      <main className={classes.content}>{children}</main>
      <div className={classes.footer}>{/* <Footer /> */}</div>
    </div>
  )
}

export default DefaultLayout
